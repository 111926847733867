import React from "react";

class ContactConfirm extends React.Component {
  render() {
    return (
      <div className="contact-form-container">
        <a className="contact-thank-you">Thank you for your submission.</a>
      </div>
    );
  }
}

export default ContactConfirm;
